<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 搜索区域 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('分组名称')">
                <a-input v-model="queryParam.groupName" :placeholder="$t('请输入分组名称')" allow-clear @pressEnter="handleQuery"/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24" >
              <a-form-item :label="$t('类型')">
                <a-select v-model="queryParam.type" :placeholder="$t('请选择类型')" allow-clear>
                  <a-select-option :value="0">{{ $t('用户') }}</a-select-option>
                  <a-select-option :value="1">{{ $t('设备') }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery">
                  <a-icon type="search" />{{ $t('查询') }}
                </a-button>
                <a-button style="margin-left: 8px" @click="resetQuery">
                  <a-icon type="redo" />{{ $t('重置') }}
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- 操作按钮区域 -->
      <div class="table-operations">
        <a-space>
          <a-button type="primary" @click="$refs.createForm.handleAdd()" >
            <a-icon type="plus" />{{ $t('新增') }}
          </a-button>
          <!-- <a-button type="primary" :disabled="single" @click="handleUpdate" v-hasPermi="['door:group:edit']">
            <a-icon type="edit" />修改
          </a-button> -->
          <a-button type="danger" :disabled="multiple" @click="handleDelete" >
            <a-icon type="delete" />{{ $t('删除') }}
          </a-button>
        </a-space>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>

      <!-- 表格区域 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
       <template slot="type" slot-scope="text, record">
          <a-tag :color="record.type === 0 ? 'blue' : 'green'">
            {{ record.type === 0 ? $t('用户') : $t('设备') }}
          </a-tag>
        </template>
        <template slot="objIds" slot-scope="text, record">
          <template v-if="record.objNames">
            <a-tooltip :title="record.objNames">
              <span>{{ record.objNames.length > 20 ? record.objNames.substring(0, 20) + '...' : record.objNames }}</span>
            </a-tooltip>
          </template>
        </template>
          <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a @click="$refs.createForm.handleUpdate(record)" >
              <a-icon type="edit" />{{ $t('修改') }}
            </a>
            <a-divider type="vertical" />
            <a @click="handleDelete(record)">
              <a-icon type="delete" />{{ $t('删除') }}
            </a>
            <template v-if="record.type === 1">
              <a-divider type="vertical" />
              <a @click="handleViewUserGroups(record)" >
                <a-icon type="team" />{{ $t('查看用户分组') }}
              </a>
            </template>
          </a-space>
        </template>
      </a-table>

      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => $t('共') + total + $t('条')"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <!-- 表单弹窗 -->
      <create-form ref="createForm" @ok="getList" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGroup, delGroup, getUserGroups } from '@/api/door/group'
import CreateForm from './modules/CreateForm.vue'
import { tableMixin } from '@/store/table-mixin'
import { Modal } from 'ant-design-vue'

export default {
  name: 'Group',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      ids: [],
      loading: false,
      total: 0,
      queryParam: {
        groupName: null,
        type: null,
        companyId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('分组名称'),
          dataIndex: 'groupName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('类型'),
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          align: 'center'
        },
        {
          title: this.$t('成员'),
          dataIndex: 'objIds',
          scopedSlots: { customRender: 'objIds' },
          align: 'center'
        },
        {
          title: this.$t('设定允许进门开始时间'),
          dataIndex: 'startTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('设定允许进门结束时间'),
          dataIndex: 'endTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center',
          width: 160
        },
        {
          title: this.$t('公司编号'),
          dataIndex: 'companyId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('操作'),
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
          width: 150,
          fixed: 'right'
        }
      ]
    }
  },
  computed: {
    single () {
      return this.selectedRowKeys.length !== 1
    },
    multiple () {
      return !this.selectedRowKeys.length
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      this.loading = true
      try {
        const res = await pageGroup(this.queryParam)
        this.list = res.data.records
        this.total = res.data.total
      } catch (error) {
        this.$message.error('获取列表失败')
      } finally {
        this.loading = false
      }
    },
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    resetQuery () {
      this.queryParam = {
        groupName: undefined,
        type: undefined,
        companyId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleUpdate () {
      const record = this.selectedRows[0]
      this.$refs.createForm.handleUpdate(record)
    },
    handleDelete (row) {
      const that = this
      const ids = row?.id ? [row.id] : this.selectedRowKeys
      this.$confirm({
        title: this.$t('确认删除所选中数据?'),
        async onOk () {
          try {
            await delGroup(ids.map(id => ({ id })))
            that.$message.success(this.$t('删除成功'))
            // that.onSelectChange([], [])
            that.getList()
          } catch (error) {
            that.$message.error(this.$t('删除失败')+error.$message)
          }
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedKeys
      this.selectedRows = selectedRows
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    async handleViewUserGroups (record) {
      try {
        const res = await getUserGroups(record.id);
        
        // 合并所有 userDOList
        const allUsers = [];
        if (res.data.doorGroupResults) {
          res.data.doorGroupResults.forEach(group => {
            if (group.userDOList) {
              allUsers.push(...group.userDOList);
            }
          });
        }

        console.log(allUsers); // 调试信息，检查数据

        // 使用 Ant Design Vue 的 Modal
        Modal.info({
          title: this.$t('用户分组详情'),
          width: 800,
          content: () => (
            <div inert>
              <a-table
                rowKey="id"
                columns={[
                  { title: this.$t('用户昵称'), dataIndex: 'nickname' },
                  { title: this.$t('手机号'), dataIndex: 'mobile' },
                  { title: this.$t('部门'), dataIndex: 'dept' },
                  { title: this.$t('创建时间'), dataIndex: 'createTime' },
                  {
                    title: this.$t('开始时间'),
                    dataIndex: 'startTime',
                  },
                  {
                    title: this.$t('结束时间'),
                    dataIndex: 'endTime',
                  }
                ]}
                dataSource={allUsers}
                pagination={false}
                size="small"
                rowClassName={(record) => (record.isManger === 1 ? 'highlight-row' : '')}
              />
            </div>
          ),
          onOk() {
            // 关闭弹框时的逻辑（如果需要）
          }
        });
      } catch (error) {
        console.error('获取用户分组详情失败:', error);
        this.$message.error(this.$t('获取用户分组详情失败'));
      }
    }
  }
}
</script>

<style lang="less">
.highlight-row {
  background-color: #ffeb3b; /* 高亮背景色 */
  font-weight: bold; /* 加粗字体 */
}
</style>