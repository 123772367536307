<template>
  <a-drawer width="35%" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
      <a-form-model-item :label="$t('分组名称')" prop="groupName">
        <a-input v-model="form.groupName" :placeholder="$t('请输入分组名称')" allow-clear />
      </a-form-model-item>
      <a-form-model-item :label="$t('开始时间')" prop="startTime">
        <a-input-number
          v-model="form.startTime"
          :placeholder="$t('请输入开始时间(0-2400)')"
          style="width: 100%"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('结束时间')" prop="endTime">
        <a-input-number
          v-model="form.endTime"
          :placeholder="$t('请输入结束时间(0-2400)')"
          style="width: 100%"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('类型')" prop="type">
        <a-radio-group 
          v-model="form.type" 
          @change="handleTypeChange"
          :disabled="formType === 2"
        >
          <a-radio :value="0">{{ $t('用户') }}</a-radio>
          <a-radio :value="1">{{ $t('设备') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <!-- 修改绑定用户分组选项 -->
      <a-form-model-item v-if="form.type === 1" :label="$t('绑定用户组')">
        <a-select
          v-model="form.doorGroupIdList"
          mode="multiple"
          :placeholder="$t('请选择用户分组')"
          :loading="userGroupLoading"
          allowClear
        >
          <a-select-option v-for="item in userGroupOptions" :key="item.id" :value="item.id">
            {{ item.groupName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    
      <a-form-model-item :label="form.type === 0 ? $t('选择用户') : $t('选择设备')" prop="objIdList">
        <!-- <a-select v-model="form.objIdList" mode="multiple">
          <a-select-option v-for="item in selectOptions" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select> -->
        <a-select
          v-model="form.objIdList"
          mode="multiple"
          :placeholder="form.type === 0 ? $t('请选择用户') : $t('请选择设备')"
          :loading="loading"
          @search="handleSearch"
          :filterOption="false"
          :notFoundContent="loading ? $t('加载中...') : (selectOptions.length === 0 ? $t('暂无数据') : null)"
          allowClear
          showSearch
          @popupScroll="handlePopupScroll"
        >
          <a-select-option v-for="item in selectOptions" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
          <a-select-option v-if="loading" disabled>
            {{ $t('加载中...') }}
          </a-select-option>
          <a-select-option v-if="!loading && !hasMore" disabled>
            {{ $t('没有更多数据') }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-footer">
      <a-space>
        <a-button :loading="submitLoading" type="primary" @click="submitForm">{{ $t('确定') }}</a-button>
        <a-button @click="onClose">{{ $t('取消') }}</a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import { getUserGroups, addGroup, updateGroup, pageGroup } from '@/api/door/group'
import { pageUser } from '@/api/user/user'
import { pageDevice } from '@/api/door/device'
import debounce from 'lodash/debounce'

export default {
  name: 'CreateForm',
  components: {VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },},
  data () {
    const validateTime = (rule, value, callback) => {
     if (value < 0 || value > 2400) {
        callback(new Error(this.$t('时间必须在0-2400之间')))
      } else if (rule.field === 'endTime' && this.form.startTime !== null && value <= this.form.startTime) {
        callback(new Error(this.$t('结束时间必须大于开始时间')))
      } else {
        callback()
      }
    }
    return {
      submitLoading: false,
      formTitle: '',
      form: {
        id: null,
        groupName: null,
        type: 0,
        createTime: null,
        companyId: null,
        objIdList:[],
        startTime: undefined,
        endTime: undefined,
        doorGroupIdList: []
      },
      selectOptions: [],
      loading: false,
      searchParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      formType: 1,
      total: 0,
      open: false,
      rules: {
        groupName: [{ required: true, message: '请输入分组名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        objIdList: [{ required: true, message: '请选择成员', trigger: 'change' }]
      },
      hasMore: true,
      userGroupOptions: [],
      userGroupLoading: false
    }
  },
  created () {
    this.rules = {
      groupName: [{ required: true, message: this.$t('请输入分组名称'), trigger: 'blur' }],
      type: [{ required: true, message: this.$t('请选择类型'), trigger: 'change' }],
      objIdList: [{ required: true, message: this.$t('请选择成员'), trigger: 'change' }]
    }
  },
  methods: {
    // 打开弹窗
    handleAdd () {
      this.reset()
      this.$nextTick(() => {
        this.$refs.form?.resetFields()
      })
      this.open = true
      this.formTitle = this.$t('新增分组')
      this.formType = 1
      // 如果初始类型是设备，加载用户分组列表
      if (this.form.type === 1) {
        this.loadUserGroups()
      }
      this.loadOptions()
    },
    // 修改
    async handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      try {
        console.log('请求的ID:', id);
        const response = await getUserGroups(id);
        console.log('获取的响应:', response);
        const data = response.data;
        // 处理数据
        this.form = {
          ...data,
          objIdList: typeof data.objIds === 'string' ? data.objIds.split(',') : data.objIds || [],
          doorGroupIdList: data.doorGroupIdList || []
        };
        
        // 加载选项数据
        await this.loadSelectedItems(this.form.objIdList);
        // 如果是设备分组，加载用户分组列表
        if (this.form.type === 1) {
          await this.loadUserGroups();
        }
        this.open = true;
        this.formTitle = this.$t('修改分组');
      } catch (error) {
        console.error('获取分组信息失败:', error);
        this.$message.error(this.$t('获取分组信息失败，请检查网络或重试'));
      }
    },
    // 类型变更
    handleTypeChange () {
      if (this.formType === 2) return
      this.form.objIdList = []
      this.selectOptions = []
      this.hasMore = true
      this.searchParams = {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      }
      // 重置用户分组相关数据
      this.form.doorGroupIdList = []
      this.userGroupOptions = []
      this.loadOptions()
    },
    // 搜索处理
    handleSearch: debounce(function (value) {
      this.searchParams.keyword = value
      this.searchParams.pageNum = 1
      this.selectOptions = []
      this.hasMore = true
      this.loadOptions()
    }, 500),
    // 加载更多
    async loadMore () {
      if (!this.hasMore || this.loading) return
      this.searchParams.pageNum++
      await this.loadOptions(true)
    },
    // 加载选项
    async loadOptions (isLoadMore = false) {
      if (this.loading) return
    
      this.loading = true
      try {
        const res = await this.getListByType()
        console.log(res.code)
        if (res.code === "20001") {
          const newOptions = this.formatOptions(res.data.records)
          this.selectOptions = isLoadMore ? [...this.selectOptions, ...newOptions] : newOptions
          this.total = res.data.total
          this.hasMore = this.selectOptions.length < this.total
        }
      } catch (error) {
        this.$message.error(`获取${this.form.type === 0 ? '用户' : '设备'}列表失败`)
      } finally {
        this.loading = false
      }
    },
    // 获取列表数据
    getListByType () {
      const api = this.form.type === 0 ? pageUser : pageDevice
      return api(this.searchParams)
    },
    // 选择变更
    handleChange (value) {
      this.form.objIds = value
    },
    // 加载已选项
    async loadSelectedItems (ids) {
      if (!ids?.length) return
      try {
        const api = this.form.type === 0 ? pageUser : pageDevice
        const res = await api({ ids: ids.join(',') })
        if (res.code === "20001") {
          this.selectOptions = this.formatOptions(res.data.records)
        }
      } catch (error) {
        console.log(error)
        this.$message.error(`获取已选${this.form.type === 0 ? '用户' : '设备'}信息失败`)
      }
    },
    formatOptions (records) {
      return records.map(item => ({
        label: this.form.type === 0 ? item.nickname : item.doorName,
        value: item.id
      }))
    },
    // 提交表单
    submitForm () {
      this.$refs.form.validate(async valid => {
        if (!valid) return
        this.submitLoading = true
        try {
          const submitData = {
            ...this.form,
            objIds: this.form.objIdList,
            doorGroupIdList: this.form.type === 1 ? this.form.doorGroupIdList : undefined
          }
          if (this.form.id) {
            await updateGroup(submitData)
            this.$message.success(this.$t('修改成功'))
          } else {
            await addGroup(submitData)
            this.$message.success(this.$t('新增成功'))
          }
          this.open = false
          this.$emit('ok')
        } catch (error) {
          this.$message.error(error.message || this.$t('操作失败'))
        } finally {
          this.submitLoading = false
        }
      })
    },
    // 重置表单
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        groupName: null,
        objIdList: [],
        type: 0,
        createTime: null,
        companyId: null,
        startTime: undefined,
        endTime: undefined,
        doorGroupIdList: []
      }
      this.selectOptions = []
      this.searchParams = {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      }
      this.hasMore = true
      this.total = 0
      this.userGroupOptions = []
      this.loading = false
      this.userGroupLoading = false
      this.submitLoading = false
    },
    // 关闭弹窗
    onClose () {
      this.open = false
      this.reset()
    },
    // 加载用户分组列表
    async loadUserGroups() {
      this.userGroupLoading = true
      try {
        const res = await pageGroup({ type: 0 })
        if (res.code === '20001') {
          this.userGroupOptions = res.data.records || []
        }
      } catch (error) {
        this.$message.error(this.$t('获取用户分组列表失败'))
      } finally {
        this.userGroupLoading = false
      }
    },
    async handleViewUserGroups (record) {
      if (!record || !record.id) {
        this.$message.error(this.$t('无效的记录，无法获取用户分组详情'));
        return;
      }

      try {
        console.log('请求的记录ID:', record.id);
        const res = await getUserGroups(record.id);
        console.log('获取的用户分组响应:', res);

        if (!res || !res.data || !res.data.doorGroupResults) {
          throw new Error(this.$t('返回的数据格式不正确'));
        }

        // 合并所有 userDOList
        const allUsers = [];
        res.data.doorGroupResults.forEach(group => {
          if (group.userDOList) {
            allUsers.push(...group.userDOList);
          }
        });

        // 显示合并后的用户列表在弹框中
        this.$modal.info({
          title: this.$t('用户分组详情'),
          width: 800,
          content: h => {
            return (
              <div>
                <a-table
                  columns={[
                    { title: this.$t('用户昵称'), dataIndex: 'nickname' },
                    { title: this.$t('手机号'), dataIndex: 'mobile' },
                    { title: this.$t('部门'), dataIndex: 'dept' },
                    { title: this.$t('创建时间'), dataIndex: 'createTime' }
                  ]}
                  dataSource={allUsers}
                  pagination={false}
                  size="small"
                />
              </div>
            );
          },
          onOk() {
            // 关闭弹框时的逻辑（如果需要）
          }
        });
      } catch (error) {
        console.error('获取用户分组详情失败:', error);
        this.$message.error(this.$t('获取用户分组详情失败，请检查网络或重试'));
      }
    },
    // 修改滚动加载方法
    handlePopupScroll(e) {
      const { target } = e
      if (!this.loading && this.hasMore && target.scrollTop + target.offsetHeight >= target.scrollHeight - 20) {
        this.loadMore()
      }
    },
  }
}
</script>

<style lang="less" scoped>
.drawer-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
</style>