import request from '@/utils/request'


// 查询分组信息列表
export function listGroup(query) {
  return request({
    url: '/door/door-group/list',
    method: 'get',
    params: query
  })
}

// 查询分组信息分页
export function pageGroup(query) {
  return request({
    url: '/door/door-group/page',
    method: 'get',
    params: query
  })
}

// 查询分组信息详细
export function getUserGroups(data) {
  return request({
    url: '/door/door-group/detail?id='+ data,
    method: 'get'
  })
}

// 新增分组信息
export function addGroup(data) {
  return request({
    url: '/door/door-group/add',
    method: 'post',
    data: data
  })
}

// 修改分组信息
export function updateGroup(data) {
  return request({
    url: '/door/door-group/edit',
    method: 'post',
    data: data
  })
}

// 删除分组信息
export function delGroup(data) {
  return request({
    url: '/door/door-group/delete',
    method: 'post',
    data: data
  })
}
